<template>
  <div>
    <editor :initial="dataItem" @data="save"></editor>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Editor from "../_components/general-editor";

export default {
  components: {
    Editor,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => ({
    dataItem: {},
  }),
  methods: {
    getCompanyDetails() {
      this.loading = true;
      const url = `/CompanyService_GetLogisticsHubInfo`;
      this.$store
        .dispatch("post", { url, data: { CompanyId: 1 } })
        .then((res) => {
          this.dataItem = res.ResponseData;
        })
        .catch((err) => {
          this.showSnackbar(err, "error");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save(record) {
      this.loading = true;
      const url = `/CompanyService_PostLogisticsHubInfo`;
      this.$store
        .dispatch("post", { url, data: record })
        .then((res) => {
          if (res.ResultCode == 1200) {
            this.showSnackbar("Data saved successfully", "success");
          } else {
            this.showSnackbar(res.ResultDesc, "error");
          }
        })
        .catch((err) => {
          this.showSnackbar(
            err.response?.data?.errors || "An error occurred",
            "error"
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },

    showSnackbar(text, color) {
      this.snackbar = {
        show: true,
        text,
        color: color === "error" ? "red" : "green",
      };
    },
  },
  created() {
    this.getCompanyDetails();
  },
};
</script>
