<template>
  <v-card class="elevation-2">
    <v-toolbar flat color="light" light>
      <v-toolbar-title>SAT Settings</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form ref="form">
        <v-tabs v-model="activeTab" background-color="primary" grow>
          <v-tab>
            <v-icon left>mdi-cog</v-icon>
            General
          </v-tab>
          <v-tab>
            <v-icon left>mdi-flag-variant</v-icon>
            Features
          </v-tab>
          <v-tab>
            <v-icon left>mdi-cellphone</v-icon>
            Mobile App
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="activeTab">
          <v-tab-item>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model.number="record.DfltDiscount"
                    label="Default Discount (%)"
                    type="number"
                    outlined
                    dense
                    :rules="[(v) => v >= 0 || 'Must be positive']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="record.DfltPaymentMthd"
                    label="Default Payment Method"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                    v-model="selectedWarehouse"
                    :items="warehouses"
                    item-text="name"
                    item-value="id"
                    label="Select Warehouse"
                    @change="handleWarehouseChange"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                    v-model="selectedRoute"
                    :items="routes"
                    item-text="name"
                    item-value="id"
                    label="Select Route"
                    @change="handleRouteChange"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="record.Code"
                    label="Code"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="record.DispName"
                    label="Display Name"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>

          <v-tab-item>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="6" md="4">
                  <v-switch v-model="record.EnableCalls" label="Enable Calls"></v-switch>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-switch
                    v-model="record.EnableRouteAssign"
                    label="Enable Route Assign"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-switch v-model="record.EnableFMS" label="Enable FMS"></v-switch>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-switch
                    v-model="record.ManualPromoEnable"
                    label="Manual Promo Enable"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-switch
                    v-model="record.AutomaticFiscalization"
                    label="Automatic Fiscalization"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-switch
                    v-model="record.ForceAppUpdate"
                    label="Force App Update"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>

          <v-tab-item>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="record.MobileAppVersion"
                    label="Mobile App Version"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="record.MobileAppPlatform"
                    label="Mobile App Platform"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="record.MobileAppReleaseDate"
                        label="Mobile App Release Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="record.MobileAppReleaseDate"
                      @input="dateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="record.MobileAppReleaseNotes"
                    label="Mobile App Release Notes"
                    outlined
                    rows="4"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="sendData" :disabled="!valid">
        <v-icon left>mdi-content-save</v-icon>
        {{ Object.keys(record).length === 0 ? "Create" : "Update" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    valid: true,
    record: {},
    dateMenu: false,
    activeTab: 0,
    warehouses: [],
    routes: [],
    selectedWarehouse: null,
    selectedRoute: null,
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
    },
    sendData() {
      this.record.CompanyId = 1;
      this.$emit("data", this.record);
    },
    getRoutes() {
      this.loading = true;
      this.$store
        .dispatch("get", `/routes_planning`)
        .then((res) => {
          this.loading = false;
          if (res.ResultCode == 1200) {
            this.routes = res.ResponseData.map((route) => ({
              id: route.Code,
              name: route.Name,
            }));
          } else {
            this.showSnackbar(res.ResultDesc, "error");
          }
        })
        .catch((err) => {
          this.loading = false;
          this.showSnackbar(err.response?.data?.errors || "An error occurred", "error");
        });
    },
    getWarehouses() {
      this.loading = true;
      this.$store
        .dispatch("get", `/warehouse`)
        .then((res) => {
          this.loading = false;
          if (res.ResultCode == 1200) {
            this.warehouses = res.ResponseData.map((warehouse) => ({
              id: warehouse.WhsCode,
              name: warehouse.WhsName,
            }));
          } else {
            this.showSnackbar(res.ResultDesc, "error");
          }
        })
        .catch((err) => {
          this.loading = false;
          this.showSnackbar(err.response?.data?.errors || "An error occurred", "error");
        });
    },
    handleWarehouseChange(value) {
      console.log("Selected warehouse:", value);
    },
    handleRouteChange(value) {
      console.log("Selected route:", value);
    },
  },
  created() {
    this.getWarehouses();
    this.getRoutes();
  },
};
</script>
